.dashboard_page_container{
  position: relative;
  margin-top: 60px;
  min-height: 100vh;
  font-family: "Arial";
  background-color: #d3d3d34f;
  
  .marketing_title{
    margin:20px 0px 0px 10px;
    font-weight: 500;
    color: #333;
    font-size: 18px!important;
    text-align: center;
  }
  .menu_container{
    display: grid;
    grid-template-columns:repeat(3, 33.33%);
    justify-items:center;
    align-items:center;

    .single_menu_container{
      display: grid;
      justify-items:center;
      align-items:center;
      text-decoration: none;
      color: white;
      width:100px;
      height: 100px;
      border-radius: 3px;
      margin-top: 20px;
      // border:5px solid white;

      .menu_icon_container{
        display: flex;
        justify-content:center;
        align-items:center;
        
      }

      .menu_label{
        margin-top: 5px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.8px;
        text-align: center;
      }
    }
    .disabled-link{
      pointer-events: none;
    }

    @media(max-width: 340px){
       grid-template-columns:repeat(2, 50%);
    }
  }

}