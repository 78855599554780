.in_dev{
  position: absolute;
  top: 50%;
  left: 50%;
  color: grey;
  transform: translate(-50%,-50%);
  text-align: center;

  .gear{
    width: 120px;
    max-width: 70%;
    margin-bottom: 10px;
  }
}

