.home_container{
  position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
  width: 100%;
  height: 100%;
  font-family: "Arial";
  overflow: hidden;
  background-color: #d3d3d34f;

  .home_dashboard_container{
    position: relative;
    display: grid;
    grid-template-rows: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;

  
    .toggles_container{
      position: relative;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(2,50%);
      justify-items:center;
      background-color:#420b3e;
      display: none;

      .toggles{
        width:100%;
        padding-bottom: 5px;
        box-sizing: border-box;
        height: auto;
        text-align:center;
        color:#333;
        transition:all 0.3s;
        cursor: pointer;
        border-bottom: 3px solid #420b3e;
        color:white;
        opacity: 0.8;
      }
      .toggles.selected{
        background-color:#420b3e;
        border-bottom: 3px solid #ff6384;
        color: white;
        opacity: 1;
      }

    }


    .list_blocks_container{
      position: relative;
      width: 100%;
      overflow-y: scroll !important;

      .lists_container{
        box-sizing:border-box;
        scrollbar-width: none;
        scrollbar-color: orange white;

      }
    }

  }

  .graph_block{
    text-align: center;
    padding-bottom: 10px;

    .yellow_shade11{
      position: relative;
      height:60px;
      width: 100%;
      background-color: #FFFFFF;

      .desigtn_block{
        position: relative;
        padding: 20px;
        box-sizing:border-box;
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        //color: white;
        color: black;
       
       
      }
    }

    .yellow_shade1{
      position: relative;
      height:60px;
      width: 100%;
      background-color: #b9097c;

      .current_month{
        position: relative;
        padding: 20px;
        box-sizing:border-box;
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: white;
      }
    }
    .yellow_shade2{
      position: relative;
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: white;

      .yellow_shade3{
        position: absolute;
        top:0%;
        left: 0%;
        width: 100%;
        height: 60px;
        background-color: #b9097c;

      }

      .sale_graph_container{
        position: relative;
        display: inline-block;
        width: 85%;
        height: auto;
        color: #333;
        text-align: left;
        font-size: 14px;
        box-sizing:border-box;
        border-radius: 10px;
        background-color: white;
        box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);

        .table_list{
          position: relative;
          width:100%;
          font-size: 10px;
          text-align: left;
          margin-top: 3px;
          border-collapse: collapse;
          
    
          thead{
            color: #fff;
            font-size: 14px;
            background-color:#0A84ff;

    
            th{
             padding:10px 4px;
            }
          }
    
          tbody{
            box-sizing:border-box;
            font-size: 12px;
            color: #444;
            text-shadow: 0px 0px 3px white;

            
    
             tr:nth-child(odd) {
              background-color: #fffff;
            }

            tr:nth-child(even) {
             background-color: #ececec;
            }
            td:first-child{
              font-size: 11px;

            }

              td{
                white-space: normal;
                font-weight: 700;
                padding: 10px 4px;
                vertical-align:middle;
              }
          }
        }
        .average_rate{
             display: grid;
             grid-template-columns: 50% 50%;
             text-align: center;
             padding:15px;
        }
        .table_list3 {
          width: 100%;
          border-collapse: collapse;
          font-size:14px;
        

        th, td {
          padding: 20px 10px 20px 10px;
          box-sizing: border-box;
        }
        th {
          letter-spacing: 2px;
        }
        
        td {
          letter-spacing: 0.5px;
          overflow: hidden;
          white-space: normal;
        }
        td:nth-child(even){
          word-break:keep-all;
          letter-spacing: 0px;
        } 
       
        tr:nth-child(odd) {
          background-color:#0A84ff;
          color:#fff;
        }
        }
        .table_list2{
          position: relative;
          width:100%;
          font-size: 10px;
          text-align: left;
          margin-top: 3px;
          border-collapse: collapse;
    
          thead{
            color: #fff;
            font-size: 14px;
            background-color:#0A84ff;
    
            th{
                padding: 10px;
            }
          }
    
          tbody{
            box-sizing:border-box;
            font-size: 12px;
            text-shadow: 0px 0px 3px white;
    
            tr:nth-child(odd) {
              background-color: #fffff;
            }

            tr:nth-child(even) {
             background-color: #ececec;
            }

              td{
                white-space: normal;
                font-weight: 800;
                padding: 15px;
                vertical-align:middle;
              }
          }
        }
      }
      
    }
    

    
  }
  .pushable {
    position: relative;
    border: none;
    background: transparent;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
    margin-top: 20px;
  }
  
  .front {
    display: block;
    position: relative;
    padding: 8px 18px;
    border-radius: 12px;
    font-size: 1rem;
    color: white;
   // background: rgb(255, 2, 2);
    background: #b9097c;
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
}