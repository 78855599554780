.price_list_page_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .search_container{
    position: relative;
    display: grid;
    grid-template-columns:max-content auto;
    width: 200px;
    height: 38px;
    overflow: hidden;
    border: 1px solid grey;
    border-radius: 25px;
    max-width: 95%;
    margin-left: 10px;
    margin-top: 5px;
    align-items:center;
    color: grey;
    padding-left: 10px;
    box-sizing:border-box;

    .product_search_input{
      padding: 4px 10px;
      box-sizing:border-box;
      width: 100%;
      height: 100%;
    }
  }


  .table_container{
    position: relative;
    width:100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    box-sizing:border-box;

    .products_price_table{
      position: relative;
      width:100%;
      font-size: 10px;
      text-align: left;
      margin-top: 3px;
      border-collapse: collapse;
      border:1px solid grey;

      thead{
        color: white;
        font-size: 14px;
        background-color:#420b3e;

        th{
          border:2px solid grey;
            padding: 5px;
        }
      }

      tbody{
        padding: 5px;
        box-sizing:border-box;
        font-size: 10px;
        color: #333;
        text-shadow: 0px 0px 3px white;

        tr{
          td{
            border:2px solid grey;
            // border:1px solid grey;
            padding: 5px;
            white-space: normal;
            font-weight: 500;

            &:nth-child(3){
              text-align:center;
            }
            &:nth-child(4){
              text-align:right;
            }
            &:nth-child(5){
              text-align:center;
            }
            &:nth-child(2){
              word-break: break-all;
             }
          }

          &:nth-child(even){
            background-color: #80808014;
          }
        }
      }
    }

   
  }

}