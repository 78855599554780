.uijs.swipemenu {

  .toggles.selected{
    background-color: #333;
    color: white;
  }
  
  .list_blocks_container{
    overflow: hidden;
    /*border: 2px solid red;*/
    white-space: nowrap;
    font-size: 0px;
  }

  .list_blocks_container *{
    font-size: inherit;
  }
  
  .list_blocks_container div{
    font-size: initial;
  }
  .list_blocks_container .lists_container{
    position: relative;
    /*float: left;*/
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px;
  }

}