$nav_height:50px;
.bottom_nav_placeholder{
	position: relative;
	height: $nav_height;
	width: 100%;
	background-color: transparent;

  .bottom_nav_container{
    position: fixed;
    bottom: 0%;
    left: 0%;
    height: $nav_height;
    padding-top: 5px;
    width: 100%;
    background-color: white;
    box-shadow: 0 5px 13px 0 #420b3e;
    overflow: hidden;

    .shuttle{
      position: absolute;
      top: 0%;
      left: 10%;
      height: 5px;
      width:18%;
      transform:translate(-50%,0%);
      padding: 0px 5px;
      box-sizing:border-box;
      border-radius: 0px 0px 5px 5px;
      background-color: #420b3e;
      transition:0.3s all;
    }

  	.bottom_nav{
      position: relative;
  		display: grid;
  		grid-template-columns:repeat(5, 20%);
      height: 100%;
  		width: 100%;
  		align-items:center;
      text-align: center;
      overflow: hidden;
  		.nav_button{
  			/*border: 1px solid red;*/
  			display: grid;
  			grid-template-rows: auto max-content;
  			grid-row-gap:3px;
  			justify-content: center;
  			align-items: center;
  			color: white;
        font-family: inherit;
        text-decoration: none;
        color: #808080b0;
        transition:0.3s all;



  			i{
  				font-size: 21px;
  			}

        div{
          font-size: 10px;
        }
  		
  		}
  		
  	}
  } 

}