.new_lead_container{
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: auto;
  font-family: "Arial";

  .page_header{
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items:center;
    width: 100%;
    padding-top: 5px;
    box-sizing:border-box;

    .page_title{
      color: #fd7e14;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.5px;
    }

    .back_button{
      color: #333;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .enquiry_form{
    position: relative;
    width:100%;
    padding: 0px 10px 10px 10px;
    box-sizing:border-box;

    .blocks{
      position: relative;
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      box-shadow: 0 0 1px rgba(0,0,0,.7),0 1px 3px rgba(0,0,0,.7);

      .input_grid{
        position: relative;
        display: grid;
        grid-template-columns:50% auto;
        grid-column-gap:10px;
      }

      .input_label{
        position: relative;
        margin-top:8px;
        color: #617973;
        font-size: 14px;
        font-family: "Arial";
      }

      .custom_input{
        position: relative;
        width: 100%;
        height: 30px;
        font-size: 14px;
        border:none;
        border-bottom: 2px solid #e9e8e8;
        caret-color: #fd7e14;
        font-family: "Arial";
        background-color: transparent;


        &:focus-within{
          border-color: #fd7e14;
        }
      }
    }

    .customer_details_container{
      margin-top: 5px;
      padding: 1px 10px 10px 10px;
      box-sizing:border-box;

      .input{
        outline: none;
        box-shadow: none;
        resize:none;

        .react-date-picker__wrapper{
          border:none;

          .react-date-picker__calendar .react-calendar{
            width: 100% !important;
          }

          .react-date-picker__inputGroup__input ::-moz-selection {
            background-color: transparent;
            color: #000;
          }

          .react-date-picker__inputGroup__input::selection {
            background-color: transparent;
            color: #000;
          }
        }
      }
      .customer_container{
        position: relative;
        
        .customer_suggestions_container{
          display: none;
          position: absolute;
          top: 102%;
          left:0%;
          width: 100%;
          height: auto;
          background-color: white;
          border-top:1px solid grey;
          border-left:1px solid grey;
          border-right:1px solid grey;
          border-radius: 3px;
          z-index: 2022;

          .single_customer_details{
            position: relative;
            width: 100%;
            font-size: 13px;
            padding: 5px;
            box-sizing:border-box;
            border-bottom: 1px solid lightgrey;
            color: #333;
            font-family: "Arial";

            &:hover{
              background-color: lightblue;
              color: white;
            }
          }
        }
      }

      .input_radio_container{
        position: relative;
        display: grid;
        margin-top: 10px;
        grid-template-columns:max-content auto;
        grid-column-gap:10px;
        align-items:center;
        font-size: 14px;
        color: #617973;
        font-family: "Arial";

        .lead_type_radio_buttons{
          display: grid;
          grid-template-columns:auto auto;
          justify-content:left;
          grid-column-gap:10px;
          align-items:center;

          .label_radio_container{
            display: grid;
            grid-template-columns:max-content auto;
            align-items:center;
            grid-column-gap:4px;
            margin:3px 0px;
            font-size: 12px;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
            }
          }
        }
      }

      .district, .enq_status{
        font-size: 13px;
        .css-yk16xz-control{
          height: 32px!important;
          min-height: auto;
          border: none;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

        }

        .css-g1d714-ValueContainer{
          padding: 0px;
        }

        .css-1pahdxg-control{
          box-shadow: none;
          border: none;
          height: 32px!important;
          min-height: auto;
          border-radius: 0px;
          border-bottom: 2px solid #e9e8e8;
          background-color: transparent;

          &:focus-within{
            border-color: #fd7e14;
          }
        }

        .pholder,.css-tj5bde-Svg{
          box-shadow: none;
        }

        .css-1okebmr-indicatorSeparator{
          visibility: hidden;
        }
      }

      .location_container{
        position: relative;
        display: grid;
        grid-template-columns:auto max-content;
        grid-column-gap:5px;

        .location{
          position: relative;
          width: 100%;
          height: 26px;
          font-size: 12px;
          border:none;
          border-bottom: 2px solid #e9e8e8;
          caret-color: #fd7e14;
          font-family: "Arial";
          background-color: transparent;


          &:focus-within{
            border-color: #fd7e14;
          }
        }

        .location_button{
          align-self:center;
          padding:5px;
          color: #398ee7;
          font-size: 22px;
        }
      }

      .folloup_date{
        height: 32px;
      }

      .enquiry_type_radio_buttons{
        display: grid;
        grid-template-columns:auto auto auto;
        justify-content:center;
        grid-column-gap:10px;
        margin-top: 10px;
        align-items:center;
        font-size: 14px;
        color: #617973;
        font-family: "Arial";

        .label_radio_container{
          display: grid;
          grid-template-columns:max-content auto;
          align-items:center;
          grid-column-gap:4px;
          font-size: 12px;

            input[type=radio] {
              width: 18px;
              height: 18px;
              margin:0px;
            }
        }
      }
    }


    .products_container{
      padding: 10px;
      box-sizing:border-box;

      .single_product_container{
        position: relative;
        border-bottom: 2px solid #420b3e;
        border-top: 2px solid #420b3e;
        padding: 0px 7px 7px 7px;
        box-sizing:border-box;
        margin-top: 10px;
        // background-color: #e6bee2;
        background:linear-gradient(to bottom right , rgba(255,255,0,0.1), rgba(0,255,255,0.1));
        transition : all 1s;

        .product_label_and_delete_button{
          display: grid;
          height: 26px;
          width:100%;
          grid-template-columns: max-content auto max-content;
          align-items:center;
          // padding: 0px 5px;
          box-sizing:border-box;

          grid-column-gap:5px;

          .product_label{
            font-size: 14px;
            font-weight: 500;
            color: green;
          }

          .maximize_button{
            display: none;
          }

          .minimize_button,.maximize_button{
            color: #420b3e;
            padding: 3px 3px 3px 0px;
            box-sizing:border-box;
          }

          .delete_product_button{
            justify-self:right;
            color: red;
            box-shadow: none;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;
            transition:all 0.3s;
            // border: 1px solid white;
            // &:hover{
            //   border: 1px solid red;
            // }

          }

        }

        .qty_unit_price_container{
          position: relative;
          display: none;
          grid-template-columns:auto auto auto;
          font-size: 12px;

          .product_total{
            // align-self:center;
            // font-size: 12px;
          }
        }

        .input_label{
          margin-top: 6px;
        }

        .product{
          font-size: 13px;
            .css-yk16xz-control{
              height: 32px!important;
              min-height: auto;
              border: none;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

            }

            .css-g1d714-ValueContainer{
              padding: 0px;
            }

            .css-1pahdxg-control{
              box-shadow: none;
              border: none;
              height: 32px!important;
              min-height: auto;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

              &:focus-within{
                border-color: #fd7e14;
              }
            }

            .pholder,.css-tj5bde-Svg{
              box-shadow: none;
            }

            .css-1okebmr-indicatorSeparator{
              visibility: hidden;
            }
        }
        

        .input{
          width:100%;
          outline: none;
          box-shadow: none;
          resize:none;
          -moz-appearance: textfield;
        }
        .qty_unit_container{
          display: grid;
          grid-template-columns:50% auto;
          grid-column-gap:10px;

          .unit{
            border: none;
            height: 32px;
            font-size: 12px;
            border:none;
            border-bottom: 2px solid #e9e8e8;
            caret-color: #fd7e14;

            .css-yk16xz-control{
              height: 32px!important;
              min-height: auto;
              border: none;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;
            }

            .css-g1d714-ValueContainer{
              padding: 0px;
              background-color: transparent;

            }

            .css-1pahdxg-control{
              box-shadow: none;
              border: none;
              height: 32px!important;
              min-height: auto;
              border-radius: 0px;
              border-bottom: 2px solid #e9e8e8;
              background-color: transparent;

              &:focus-within{
                border-color: #fd7e14;
              }
            }

            .pholder,.css-tj5bde-Svg{
              box-shadow: none;
            }

            .css-1okebmr-indicatorSeparator{
              visibility: hidden;
            }
          }
        }
        &:not(:last-child){
          // border: 2px solid red;
        }
      }

      .price_and_add_button_container{
        position: relative;
        display: grid;
        grid-template-columns:auto max-content;
        margin-top: 5px;
        align-items:center;

        .add_product_button{
          position: relative;
          justify-self:right;
          padding: 10px 20px;
          background-color: green;
          color: white;
          box-shadow: none;
          border: none;
          border-radius: 25px;
          cursor: pointer;
          text-decoration: none;
          transition:all 0.3s;
          &:hover{
            background-color: #002400;
            color: lightgrey;
          }
        }

      }
      .remarks{
        outline: none;
        box-shadow: none;
        resize:none;
      }
    }
    .submit_button{
      position: relative;
      display: block;
      margin:10px auto;
      // width: 250px;
      width: 100%;
      height: 45px;
      background-color: #1a73e9;
      color: white;
      box-shadow: none;
      border: none;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
      transition:all 0.3s;

      &:hover{
        background-color: #3e70e8;
        color: lightgrey;
      }
    }
  }

}