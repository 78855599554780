.header_container{
  position: fixed;
  left: 0%;
  top:0%;
  display: grid;
  grid-template-columns: auto auto max-content;
  height: 60px;
  width: 100%;
  background-color: #420b3e;
  z-index: 2020;
  // background-color: #0057ff;
  align-content:center;

  .app_name{
    display: flex;
    align-items:center;
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: white;
    letter-spacing: 1px;
   }

   .name_division_container{
    display: grid;
    align-items:center;
    justify-items:center;
    text-align: center;
    .username{
      width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      color: white;
      font-weight: 500;

    }
    .division{
      width: 140px;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 500;
      

      .css-yk16xz-control{
        height: 32px!important;
        min-height: auto;
        border: none;
        border-radius: 0px;
        // border-bottom: 2px solid #e9e8e8;
        background-color: transparent;
        color: #ff6384;

      }

      .css-g1d714-ValueContainer, .css-1uccc91-singleValue{
        padding: 0px;
        color: #ff6384;
        justify-content: center;
      }

      .css-1pahdxg-control{
        box-shadow: none;
        border: none;
        height: 32px!important;
        min-height: auto;
        border-radius: 0px;
        // border-bottom: 2px solid #e9e8e8;
        background-color: transparent;
        color: #ff6384;

        // &:focus-within{
        //   border-color: #fd7e14;
        // }
      }
      .css-tj5bde-Svg{
        color: #ff6384;
      }

      .pholder,.css-tj5bde-Svg{
        box-shadow: none;
      }

      .css-1okebmr-indicatorSeparator{
        visibility: hidden;
      }
    }
  }

  
  .logout_button{
    font-size: 19px;
    color: white;
  }
}