.loading_container{
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
  // background-color: rgba(255,255,255,0.5);
	background-color: rgba(255,255,255,0);
	z-index: 2021;

	.loading_symbol{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		color: #333;
		font-size: 16px;
		font-family: "Lato",sans-serif;

		svg {
  			animation: .6s linear infinite rotate;
		}

		svg path,
		svg rect{
		    fill: #2a76f2;
		}
	}

	@keyframes rotate { from { transform: rotate(0); } to { transform: rotate(360deg); }  }

}

	