.leads_page_container{
	position: absolute;
  left:0%;
  top: 0%;
  display: grid;
  grid-template-rows: 60px auto 55px;
	width: 100%;
	height: 100%;
	font-family: "Arial";
  overflow: hidden;

  .leads_container{
    position: relative;
    display: grid;
    grid-template-rows: max-content max-content auto;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .page_header{
      position: relative;
      width: 100%;
      // height: 50px;
      display: grid;
      grid-template-columns: auto max-content;
      background-color: #ea8000;
      // border-bottom: 1px solid #333;
      align-items:center;
      padding:5px 5px 5px 10px;
      box-sizing:border-box;

      .page_title{
        color: white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }

      .new_lead_button{
        color:white;
        font-size: 14px;
        font-weight: 500;
        font-family: "Arial";
        padding: 7px 16px;
        background-color: #058b00;
        border : 1px solid lightgrey;
        border-radius: 20px;
        cursor: pointer;
        text-decoration: none;

        &:hover{
          background-color: #058b71;
          color: lightgrey;
        }
      }
    }

    .toggles_container{
      position: relative;
      padding:8px auto;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(3, 33.33%);
      // grid-columns-gap:5px;
      justify-items:center;
      background-color: #d3d3d35e;

      .toggles{
        display: grid;
        width:100%;
        // border:1px solid lightgrey;
        height: 70px;
        justify-items:center;
        grid-template-rows: auto auto auto;
        grid-row-gap:3px;
        align-content:center;
        color: #333;
        transition:all 0.3s;
        cursor: pointer;

        .status_icon{
          font-size: 22px;
        }
        
        .status{
          text-align: center;
          font-size: 10px;
          font-weight: 600;
        }

        .count{
          font-size: 12px;
          font-weight: 600;
        }
      }
     
    }

    .list_blocks_container{
      position: relative;
      width: 100%;
      height: auto;

      .lists_container{
        padding: 10px;
        box-sizing:border-box;
        scrollbar-width: thin;
        scrollbar-color: orange white;

        .single_lead_container{
          position: relative;
          width:100%;
          margin-bottom: 10px;
          padding: 4px 8px 8px 8px;
          box-sizing:border-box;
          height: 60px;
          border-bottom: 2px solid #420b3e;
          overflow: hidden;
          background-color: #d3d3d33d;
          transition: 0.6s height;

          .lead_details_container{
            position: relative;
            display: grid;
            grid-template-columns:auto max-content max-content max-content;
            width:100%;

            .lead_customer_name{
              position: relative;
              font-weight: 500;
              color:#ea8000;
              font-size: 16px;
              white-space: pre-line;

            }

            .lead_location, .lead_status{
              font-size: 12px;
              white-space: pre-line;
            }

            .lead_location{
              height: 14px;
              overflow: hidden;
              transition: 0.6s height;

            }

            .lead_approve_button{
              align-self:center;
              color: #2eaa2e;;
              cursor: pointer;
              font-size: 18px;

              i{
                margin-left: 20px;
              }

            }

            .lead_edit_button{
              align-self:top;
              color:#1a73e9;
              cursor: pointer;
              font-size: 17px;
              i{
                margin: 10px 0px 5px 20px;
              }
            }

            .lead_products_button{
              align-self:top;
              color: #333;
              cursor: pointer;
              font-size: 29px;
              justify-self:right;

              .arrow_down{
                margin: 5px 5px 0px 20px;
                transition:all .5s;
              }
            }
          }

          .lead_products_container{
            position: relative;
            width:100%;
            font-size: 10px;
            text-align: left;
            margin-top: 3px;

            thead{
              color: #ea8000;
              font-size: 10px;
            }

            tbody{
              padding: 5px;
              box-sizing:border-box;
              font-size: 10px;
              tr{
                td{
                   white-space: normal;
                }
              }
            }
          }
        }
      }
    }

  }

}