body{
  margin: 0px;
  font-family: "Lato",sans-serif;
}
input{
  outline: 0;
  border: none;
  padding: 0px;
}
.login_page_container{
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  min-height: 500px;
  height: 100%;
  overflow: hidden;
  visibility: hidden;

  .login_form{
    position: fixed;
    left: 50%;
    top: 45%;
    transform: translate(-50%,-50%);
    width: 400px;
    max-width: 90%;
    height:auto;
    /*padding: 20px 0px;*/
    // box-sizing: border-box;
    text-align: center;
    /*box-shadow: 0 0 50px -15px grey; */

    .company_logo{
      position: relative;
      display: block;
      margin: 0px auto 30px auto;
    }

    .input_container{
      position: relative;
      display: grid;
      grid-template-columns: 45px auto;
      width: 90%;
      margin: 20px auto 0px auto;
      height: 50px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid grey;

      .input_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color:white;
        color: black;

      }
      .input_container input{
        width: 100%;
        height: 100%;
        padding-left: 5px;
        font-size: 16px;
        box-sizing: border-box;

      }

      &:focus-within{
        border:2px solid #333;
      }
    }
    .select_container{
      position: relative;
      width: 90%;
      margin: 20px auto 0px auto;
      height: 50px;
      border-radius: 5px;
      border: 1px solid grey;

      .input_icon{
        position: absolute;
        left: 0%;
        top:0%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 100%;
        background-color:white;
        color: black;
      border-radius: 5px;


      }
      .division_select{
        height: 100%;
        border:none;
        border-radius: 6px; 
        font-family: inherit;
        font-size: 14px;
        letter-spacing: normal;

        .css-yk16xz-control{
          padding-left: 35px;
          height: 100%;

          .css-g1d714-ValueContainer{
            height: 100%;
          }
        }
        
        .css-1pahdxg-control{
          padding-left: 35px;
          box-shadow: none;
          height: 100%;

        }

        *{
          border:none;
          text-align: left;
        }

        .pholder,.css-tj5bde-Svg{
          box-shadow: none;
          color:#7a7a7a;
        }

        .css-1okebmr-indicatorSeparator{
          visibility: hidden;
        }
      }

      .css-26l3qy-menu{
        background-color: white!important;
      }

      &:focus-within{
        border:2px solid #333;
      }

    }
    

    .login_error{
      display: none;
      position: relative;
      margin-top: 5px;
      font-size: 12px;
      color: red;
    }
    .login_button{
      position: relative;
      display: block;
      border:none;
      width: 250px;
      max-width: 95%;
      height: 45px;
      background-color: green;
      color: white;
      border-radius: 5px;
      margin:20px auto;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.4s;

      &:hover{
        background-color: #002b00;
      }
    }
  }
  
  .footer{
    position: absolute;
    // background-color: red;
    bottom: 2%;
    left:50%;
    font-size: 10px;
    transform: translate(-50%,0%);
    opacity: 0.7;
    color: #4f4c4c;
    text-align:center;

    .developed_by{
      margin-bottom: 10px;
    }
    .version{
      margin-top: 5px;
    }

  }

  .ghost{
    position: absolute;
    display: none;
    top:0%;
    right:0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .update_apk_container{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    width: 90%;
    max-width: 500px;
    height: auto;
    padding:20px;
    text-align: center;
    box-sizing:border-box;
    background-color: white;

    .update_message{
      position: relative;
      color: #333;
      font-size: 14px;
    }

    .update_apk_button{
      position: relative;
      display: inline-block;
      margin-top: 20px;
      padding:8px 20px;
      background-color: orangered;
      color: white;
      font-weight: 500;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

